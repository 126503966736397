import React from 'react';
import Helmet from 'react-helmet';
import {Link,graphql,useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import settings from '../../settings';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import '../../styles/pages/veneer.scss';

import SectionImageLeft from '../../components/SectionImageLeft';
import SectionImageRight from '../../components/SectionImageRight';
import TextHeadingParagraph from '../../components/TextHeadingParagraph';

import Reveal from '../../components/Reveal';




export default function() {

	const style_ul = {
		fontStyle: 'Oblique',
		fontWeight: '100',
		paddingLeft: '16px',
	};
	
	const section_height = '400px';

	const {
			imageMobile_inhouseVeneer_01,	imageDesktop_inhouseVeneer_01,
			imageMobile_inhouseVeneer_02,	imageDesktop_inhouseVeneer_02,
			imageMobile_gridVeneer_01,		imageDesktop_gridVeneer_01,
			imageMobile_gridVeneer_02,		imageDesktop_gridVeneer_02,
			imageMobile_gridVeneer_03,		imageDesktop_gridVeneer_03,
			imageMobile_gridVeneer_04,		imageDesktop_gridVeneer_04,
			imageMobile_gridVeneer_05,		imageDesktop_gridVeneer_05,
			imageMobile_gridVeneer_06,		imageDesktop_gridVeneer_06,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_inhouseVeneer_01: file(relativePath: { eq: "pages/veneer/inhouse.veneer.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_inhouseVeneer_01: file(relativePath: { eq: "pages/veneer/inhouse.veneer.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_inhouseVeneer_02: file(relativePath: { eq: "pages/veneer/inhouse.veneer.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_inhouseVeneer_02: file(relativePath: { eq: "pages/veneer/inhouse.veneer.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_gridVeneer_01: file(relativePath: { eq: "pages/veneer/istock.veneer.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_01: file(relativePath: { eq: "pages/veneer/istock.veneer.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_gridVeneer_02: file(relativePath: { eq: "pages/veneer/istock.veneer.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_02: file(relativePath: { eq: "pages/veneer/istock.veneer.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_gridVeneer_03: file(relativePath: { eq: "pages/veneer/istock.veneer.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_03: file(relativePath: { eq: "pages/veneer/istock.veneer.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
			imageMobile_gridVeneer_04: file(relativePath: { eq: "pages/veneer/istock.veneer.04.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_04: file(relativePath: { eq: "pages/veneer/istock.veneer.04.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
			imageMobile_gridVeneer_05: file(relativePath: { eq: "pages/veneer/istock.veneer.05.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_05: file(relativePath: { eq: "pages/veneer/istock.veneer.05.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
			imageMobile_gridVeneer_06: file(relativePath: { eq: "pages/veneer/istock.veneer.06.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gridVeneer_06: file(relativePath: { eq: "pages/veneer/istock.veneer.06.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
  );

	const image_sources	=	{

		gridVeneer_01	:	[
			imageMobile_gridVeneer_01.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gridVeneer_02	:	[
			imageMobile_gridVeneer_02.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_02.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gridVeneer_03	:	[
			imageMobile_gridVeneer_03.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_03.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gridVeneer_04	:	[
			imageMobile_gridVeneer_04.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_04.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gridVeneer_05	:	[
			imageMobile_gridVeneer_05.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_05.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gridVeneer_06	:	[
			imageMobile_gridVeneer_06.childImageSharp.fluid,
			{
			...imageDesktop_gridVeneer_06.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

	};

	return (
		<Layout>
			<Helmet>
				<title>Veneer • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/veneer`} />
				<meta
					name="description"
					content="Premium quality veneer services in Southern Vermont. Able to install both full-body or regular veneer using real authentic cut stone."
				/>
			</Helmet>
			<Banner shadow={true} isH1={true} >Veneer</Banner>

			<SectionImageRight
				imageMobile={imageMobile_inhouseVeneer_01}
				imageDesktop={imageDesktop_inhouseVeneer_01}
				alt="Stone Veneer as Exterior Wall"
				height={section_height}
			>
				<TextHeadingParagraph heading="Natural Stone">
					We use <strong>natural</strong> 'cut' stone; <i>the real stuff</i>.
					Veneer comes in two flavors, natural & manufactured.
					This is not artificial stone and you can both see and feel the difference.
				</TextHeadingParagraph>
			</SectionImageRight>
			<SectionImageLeft
				imageMobile={imageMobile_inhouseVeneer_02}
				imageDesktop={imageDesktop_inhouseVeneer_02}
				alt="Stone Veneer as Support Columns"
				height={section_height}
			>
				<TextHeadingParagraph heading="Usage">
					The applications are very flexible.
					Stone veneer can take the place and appearance of any traditional stone structure.
					In addition, it can be used in places where traditional stone structures aren't possible or practical.
					For example, your foundation might not support the weight of a complete stone feature, yet
					veneer can provide features you desire while remaining within constraints of the building or foundation.
				</TextHeadingParagraph>
			</SectionImageLeft>

			<div style={{backgroundColor:settings.color.bg.dark}}>
			<div className="container">
				<div className="column">
					<div className="row">

						<Tile bgImageSources={image_sources.gridVeneer_01} >
							<div><h5>Characteristics</h5></div>
							<ul style={style_ul}>
								<li>Durable (<font color="grey">it's stone!</font>)</li>
								<li>Fire resistant</li>
								<li>Maintenance free</li>
								<li>Longevity</li>
							</ul>
						</Tile>

						<Tile bgImageSources={image_sources.gridVeneer_02} >
							<div><h5>Construction</h5></div>
							<ul style={style_ul}>
								<li>Depth: 1&#189;&quot; - 2&quot; (<font color="grey">for 'thin' veneer</font>)</li>
								<li>Depth: 5&quot; - 6&quot; (<font color="grey">for 'full body' veneer</font>)</li>
								<li>Weight: under 10 lb / ft<sup>2</sup></li>
							</ul>
						</Tile>

						<Tile bgImageSources={image_sources.gridVeneer_03} >
							<div><h5>Applications</h5></div>
							<ul style={style_ul}>
								<li>Protective / Decorative</li>
								<li>Interior / Exterior</li>
								<li>Vertical Surfaces</li>
							</ul>
						</Tile>

						<Tile bgImageSources={image_sources.gridVeneer_04} >
							<div><h5>Advantages</h5></div>
							<ul style={style_ul}>
								<li>Variety (<font color="grey">design and color</font>)</li>
								<li>Required installation resources are different than typical hardscapes</li>
								<li>Weight (<font color="grey">less than a complete hardscape</font>)</li>
								<li>Removable (<font color="grey">you can change your mind</font>)</li>
							</ul>
						</Tile>
						
						<Tile bgImageSources={image_sources.gridVeneer_05} >
							<div><h5>Acceptance</h5></div>
							<ul style={style_ul}>
								<li>Stone veneer is an established method</li>
								<li>Broad history of use in both residential and commercial applications</li>
							</ul>
						</Tile>
			
						<Tile bgImageSources={image_sources.gridVeneer_06} >
							<div><h5>Fabrication process</h5></div>
							<p style={{fontStyle:'Oblique',fontWeight:'100'}}>
								Lightweight concrete mix is poured into forms as a base for pre-selected pieces of real 'cut' stone.
								The resulting formed veneer is attached to the final vertical surface with Type 'S' mortar.
							</p>
						</Tile>

					</div>
				</div>
			</div>
			</div>
			<Banner shadow={true} bgColor="Grey" styleBg={{
				height: '80px',
			}} styleText={{
				height: '80px',
				lineHeight: '80px',
				fontSize: '22px'
			}}>
				<Link to="/contact" style={{color:settings.color.font.dark}}>Contact Us <span role="img" aria-label="Rolodex">📇</span></Link>
			</Banner>
		</Layout>
	);
}

function Tile({children,bgImageSources}){

	const classnames_tile = [
		'col-12',
		'col-sm-6',
		'col-lg-4',
		'p-4',
		'tile',
	].join(' ');

	const styles_tile = {
		backgroundPosition: '50% 50%',
		backgroundSize: 'cover',
//		minHeight: '300px',	//	Defined in .scss
		position: 'relative',
		color: '#ebebeb',
	};



	return (
		<BackgroundImage className={classnames_tile} fluid={bgImageSources} style={styles_tile} >
			<div
				className="mask"
				style={{
				backgroundColor: 'RGBa(0,0,0,0.8)',
				position: 'absolute',
					top: '0',
					right: '0',
					bottom: '0',
					left: '0',
				}}
			></div>
			<Reveal>
				<div className="text" style={{position: 'relative',}}>{children}</div>
			</Reveal>
		</BackgroundImage>
	);

}